<template>
  <b-card>
    <info />
  </b-card>
</template>

<script>

import {BRow, BCard} from 'bootstrap-vue'
import Info from './include/Info.vue'

export default {
  components: {
    BRow,
    BCard,
    Info
  },
}
</script>
