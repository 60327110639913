<template>
  <b-card>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      :isdelete="status == 'draft'"
      :isedit="status == 'draft'"
      @readData="showData"
    >
      <template v-slot:buttonaction>
        <b-button
          v-if="status == 'draft'"
          class="mr-1"
          size="sm"
          variant="success"
          type="button"
          @click.prevent="confirmAccept()"
        >
          
          <span>Setujui</span>
        </b-button>
      </template>
    </base-info>
  </b-card>
</template>

<script>

import {BCard, BButton} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'

export default {
  components: {
    BCard,
    BaseInfo,
    BButton
  },

  data() {
    return {
      dataurl:"/skko",
      baseroute:"skko",
      fields: [
        { key: 'number', label: 'Code', bold:true},
        { key: 'vendor.name', label: 'Vendor'},
        { key: 'date_start', label: 'Periode Awal' },
        { key: 'date_end', label: 'Periode Akhir'},
        { key: 'year', label: 'Tahun'},
        { key: 'nominal', label: 'Nominal', type:'number'},
        { key: 'description', label: 'Keterangan'},
        { key: 'status', label: 'Status', type:'spanKey', data:{draft:'Draft', final:'Final'}}
      ],
      status : null
    }
  },
  methods:{
    showData(result){
      this.model = result
      this.status = result.status
    },
    confirmAccept(){
      const id = this.$route.params.id
      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/'+id+'/final')
              .then(() => {
                this.$refs.baseinfo.fetchData()
            })
          }
        })
    }
  }
}
</script>
